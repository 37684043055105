import axios from "axios";
import qs from "qs";

import { ParsedAddress } from "~/src/types/addresses";
import { GoogleMapsGeocodingResponse } from "~/src/types/google-maps";
import namespaced from "~/src/utils/debug";
import { isProd, isStaging } from "~/src/utils/environment";
import { parseAddress } from "~/src/utils/google-maps";

const debug = namespaced("api-client/google-maps", { includeStaging: true });

/**
 * Docs here
 * https://developers.google.com/maps/documentation/geocoding/requests-geocoding
 */

/**
 * TODO: Restrict production token to https://examedi.com & https://staging.examedi.com
 */
export const googleMapsAccessToken =
  isProd || isStaging ? "AIzaSyDxXE4MzfkDJkqJE82zedP64a9XGLv-6Tg" : "AIzaSyBBJmRxPoN3N6L5F8MVlUsRBID9_TsM90w";

const client = axios.create({ baseURL: "https://maps.googleapis.com" });
// ?address=1600+Amphitheatre+Parkway,+Mountain+View,+CA&key=YOUR_API_KEY

async function callGoogleGeocoding(searchText: string): Promise<GoogleMapsGeocodingResponse | undefined> {
  try {
    const res = await client.get<GoogleMapsGeocodingResponse>(
      `/maps/api/geocode/json?${qs.stringify(
        {
          address: searchText,
          components: "country:CL", // TODO: Add Mexico for releasing
          key: googleMapsAccessToken,
          language: "es-419", // Spanish (Latin American)
        },
        {},
      )}`,
    );
    return res.data;
  } catch (err) {
    debug("Unexpected error calling Google Maps Forward Geocoding endpoint");
    return undefined;
  }
}

export async function forwardGeocoding(searchText: string): Promise<ParsedAddress[] | undefined> {
  if (!searchText) return undefined;
  const res = await callGoogleGeocoding(searchText);
  if (res) {
    debug("[forwardGeocoding] response was\n", res);

    const parsedAddresses: ParsedAddress[] = [];
    for (const result of res.results) {
      const pas = parseAddress(result, searchText);
      if (!pas) continue;
      for (const pa of pas) {
        if (!pa) continue;
        parsedAddresses.push(pa);
      }
    }

    debug("[forwardGeocoding] parsed addresses are\n", parsedAddresses);
    return parsedAddresses.length ? parsedAddresses : undefined;
  } else {
    debug("Unexpected falsy response from Google Maps ForwardGeocoding endpoint:", res);

    return undefined;
  }
}
