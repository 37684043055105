import React, { useEffect, useRef, useState } from "react";

import clsx from "clsx";

import { searchServices } from "~/src/api-client/services";
import { SupportedCommune } from "~/src/constants/places";
import { useContextValue } from "~/src/context";
//import { useLocale } from "~/src/hooks/useLocale";
import useOnClickOutside from "~/src/hooks/useOnClickOutside";
import LoadingSpinnerIcon from "~/src/icons/LoadingSpinnerIcon";
import PlusCircleBlueIcon from "~/src/icons/PlusCircleBlueIcon";
import { SearchItem } from "~/src/types/models";
import namespaced from "~/src/utils/debug";
import { numberWithPeriods } from "~/src/utils/number";

/**
 * README
 *
 * Put me inside a div and that's it. I'll use all the available space.
 *
 * Use divProps, inputProps & resultProps to customize font size, borders, drop shadows, etc.
 *
 */

const debug = namespaced("components/inputs/InputSearchServices");

type InputSearchServicesProps = {
  rounding: "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "full" | "none";
  divProps: React.ComponentPropsWithoutRef<"div">;
  inputProps: React.ComponentPropsWithoutRef<"input">;
  handleServiceSelect: (service: any) => void;
  resultProps: React.ComponentPropsWithoutRef<"div">;
  icon: JSX.Element;
  link?: JSX.Element;
  clearCoupon?: () => void;
  alwaysShowList?: boolean;
};

function InputSearchServices({
  rounding,
  handleServiceSelect,
  divProps,
  inputProps,
  resultProps,
  icon,
  link,
  clearCoupon,
  alwaysShowList = false,
}: InputSearchServicesProps) {
  const [availableServices, setAvailableServices] = useState<SearchItem[]>([]);
  const [search, setSearch] = useState<string | null | undefined>("");
  const [showResults, setShowResults] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { selectedAddress } = useContextValue();
  //const { locale } = useLocale();

  const containerRef = useRef<HTMLDivElement>(null);

  const fetchServices = async (query: string | null | undefined) => {
    if (!query) return;
    setLoading(true);
    const res = await searchServices({
      commune: selectedAddress?.commune as SupportedCommune | undefined,
      query: query,
      countryCode: "cl",
      category: "all",
      source: "marketplace",
    });
    if (res) {
      debug("Fetched services are", res.length);
      setAvailableServices(res);
      setShowResults(alwaysShowList || true);
    } else {
      debug("failed fetchServices");
    }
    setLoading(false);
  };

  const handleClickOutside = () => setShowResults(alwaysShowList || false);

  const handleFocus = () => setShowResults(alwaysShowList || true);

  const handleChange = (ev: React.FormEvent<HTMLInputElement>) => setSearch(ev.currentTarget.value);

  const handleServiceClick = (service: SearchItem) => {
    if (clearCoupon) clearCoupon();
    handleServiceSelect(service);
    debug(service, "service");
  };

  // TODO: remove listener if showResults changes to `false`
  useOnClickOutside(containerRef, function handleShowingResultsCalledFromInputSearchServices() {
    handleClickOutside();
  });

  useEffect(() => {
    fetchServices(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    setShowResults(alwaysShowList);
  }, [alwaysShowList]);

  const { className: divClassName, ...divOtherProps } = divProps;
  const { className: inputClassName, ...inputOtherProps } = inputProps;
  const { className: resultClassName, ...resultOtherProps } = resultProps;

  return (
    <div
      ref={containerRef}
      className={clsx(
        "w-full h-full",
        /**
         * Exams result needs to be on top of everything except notifications & development tools, so z-40
         */
        "z-30",
        "relative",
        rounding === "sm" && "rounded-t-sm",
        rounding === "sm" && !showResults && "rounded-b-sm",
        rounding === "md" && "rounded-t-md",
        rounding === "md" && !showResults && "rounded-b-md",
        rounding === "lg" && "rounded-t-lg",
        rounding === "lg" && !showResults && "rounded-b-lg",
        rounding === "xl" && "rounded-t-xl",
        rounding === "xl" && !showResults && "rounded-b-xl",
        rounding === "2xl" && "rounded-t-2xl",
        rounding === "2xl" && !showResults && "rounded-b-2xl",
        rounding === "3xl" && "rounded-t-3xl",
        rounding === "3xl" && !showResults && "rounded-b-3xl",
        rounding === "full" && "rounded-t-full",
        rounding === "full" && !showResults && "rounded-b-full",
        rounding === "none" && "rounded-t-none",
        rounding === "none" && !showResults && "rounded-b-none",
        "bg-white",
        "flex",
        divClassName,
      )}
      {...divOtherProps}
    >
      {/*
       *
       * Icon
       *
       */}
      <div className={clsx("h-full w-4", "flex justify-center items-center", "ml-3 xs:ml-4 md:ml-5 mr-1 my-auto")}>
        {icon}
      </div>
      <div className="w-full grid grid-cols-12">
        {/*
         *
         * Input
         *
         */}
        <div className={clsx("h-full", link && "col-span-9", !link && "col-span-full")}>
          <input
            className={clsx(
              "w-full",
              "h-full",
              "focus:outline-none",
              "text-sm",
              rounding === "sm" && "rounded-r-sm",
              rounding === "md" && "rounded-r-md",
              rounding === "lg" && "rounded-r-lg",
              rounding === "xl" && "rounded-r-xl",
              rounding === "2xl" && "rounded-r-2xl",
              rounding === "3xl" && "rounded-r-3xl",
              rounding === "full" && "rounded-r-full",
              rounding === "none" && "rounded-r-none",
              inputClassName,
            )}
            {...inputOtherProps}
            onChange={handleChange}
            onFocus={handleFocus}
          />
        </div>
        {/*
         *
         * Link
         *
         */}
        {!!link && <div className="col-span-3">{link}</div>}
      </div>
      {/*
       *
       * Result
       *
       */}
      {!loading && !!availableServices?.length && showResults && (
        <div
          className={clsx(
            "absolute",
            "w-[calc(100%_+_2px)]",
            "top-full -left-[1px]",
            "overflow-y-scroll no-scrollbar",
            rounding === "sm" && "rounded-b-sm",
            rounding === "md" && "rounded-b-md",
            rounding === "lg" && "rounded-b-lg",
            rounding === "xl" && "rounded-b-xl",
            rounding === "2xl" && "rounded-b-2xl",
            rounding === "3xl" && "rounded-b-3xl",
            rounding === "full" && "rounded-b-full",
            rounding === "none" && "rounded-b-none",
            "bg-white",
            resultClassName,
          )}
          {...resultOtherProps}
        >
          {availableServices.map((as, index) => (
            <div
              key={`r-${index}`}
              className={clsx(
                "w-full",
                "grid grid-cols-12",
                "cursor-pointer",
                "py-2 xl:py-3",
                "border-b border-gray-200",
                "hover:bg-gray-100",
              )}
              onClick={() => handleServiceClick(as)}
            >
              <div className="col-span-2 my-auto mx-auto">
                <PlusCircleBlueIcon />
              </div>
              <div className={clsx("col-span-7", "my-auto", "text-left", "text-xs")}>{as.name}</div>
              <div className={clsx("col-span-3", "my-auto", "text-right", "pr-3 xl:pr-4", "text-xs")}>
                ${numberWithPeriods(as.price)}
              </div>
            </div>
          ))}
        </div>
      )}
      {!loading && !availableServices?.length && showResults && (
        <div
          className={clsx(
            "absolute py-2 text-center",
            "w-[calc(100%_+_2px)]",
            "top-full -left-[1px]",
            "overflow-y-scroll no-scrollbar",
            rounding === "sm" && "rounded-b-sm",
            rounding === "md" && "rounded-b-md",
            rounding === "lg" && "rounded-b-lg",
            rounding === "xl" && "rounded-b-xl",
            rounding === "2xl" && "rounded-b-2xl",
            rounding === "3xl" && "rounded-b-3xl",
            rounding === "full" && "rounded-b-full",
            rounding === "none" && "rounded-b-none",
            "bg-white",
            resultClassName,
          )}
          {...resultOtherProps}
        >
          No se han encontrado servicios.
        </div>
      )}
      {loading && showResults && (
        <div
          className={clsx(
            "absolute py-2",
            "w-[calc(100%_+_2px)]",
            "top-full -left-[1px]",
            "overflow-y-scroll no-scrollbar",
            rounding === "sm" && "rounded-b-sm",
            rounding === "md" && "rounded-b-md",
            rounding === "lg" && "rounded-b-lg",
            rounding === "xl" && "rounded-b-xl",
            rounding === "2xl" && "rounded-b-2xl",
            rounding === "3xl" && "rounded-b-3xl",
            rounding === "full" && "rounded-b-full",
            rounding === "none" && "rounded-b-none",
            "bg-white",
            resultClassName,
          )}
          {...resultOtherProps}
        >
          <LoadingSpinnerIcon />
        </div>
      )}
    </div>
  );
}

export default React.memo(InputSearchServices);
