import React from "react";

function LocationIcon(props: React.ComponentPropsWithoutRef<"svg">) {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="10.6089" cy="10.0977" r="1.66165" fill="#B1B1B1" />
      <path
        d="M6.18271 5.5049C8.56708 3.12053 12.4329 3.12053 14.8173 5.5049C17.0101 7.69772 17.2108 11.1861 15.2839 13.6159L11.2441 18.7101C10.8639 19.1896 10.1361 19.1896 9.75588 18.7101L5.71608 13.6159C3.7892 11.1861 3.98989 7.69773 6.18271 5.5049Z"
        stroke="#B1B1B1"
        strokeWidth="1.67954"
      />
    </svg>
  );
}

export default LocationIcon;
