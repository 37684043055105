import React, { useEffect, useMemo, useState } from "react";

import clsx from "clsx";
import { navigate, PageProps } from "gatsby";
import moment from "moment";
import Switch from "react-switch";
import { useCopyToClipboard } from "react-use";
import { format as rutParser } from "rut.js";
import { v4 as uuidv4 } from "uuid";

import { getMedicProfile } from "~/src/api-client/medics";
import { getPatient, getPatientByDocumentNumber } from "~/src/api-client/patients";
import DashboardLayout from "~/src/components/DashboardLayout";
import InputAddress from "~/src/components/inputs/InputAddress";
import InputSearchServices from "~/src/components/inputs/InputSearchServices";
import PrescriptionPreview from "~/src/components/Prescription/PrescriptionPreview";
import { useContextUpdate, useContextValue } from "~/src/context";
import MagnifyingGlassGrayIcon from "~/src/icons/MagnifyingGlassGrayIcon";
import { ParsedAddress } from "~/src/types/addresses";
import { ExtraPrescriptionData, PrescriptionPatient, Service } from "~/src/types/models";
import { isProd } from "~/src/utils/environment";

const domain = !isProd ? "staging.examedi.com" : "examedi.com";

type PrescriptionProps = {
  location: PageProps["location"] & { state: { patientId?: string; telehealthId?: string } };
};

const Prescriptions = ({ location }: PrescriptionProps) => {
  const patientId: string | undefined = location.state?.patientId;
  const telehealthId: string | undefined = location.state?.telehealthId;
  const { session, rehydrated, medic } = useContextValue();
  const { setMedicKeys, setPreLoadingStatus } = useContextUpdate();
  const [selectedServices, setSelectedServices] = useState<Service[]>([]);
  const [newService, setNewService] = useState<string>("");
  const [fullAddress, setFullAddress] = useState<ParsedAddress>();
  const [chileanPatient, setChileanPatient] = useState<boolean>(true);
  const [disableValidateButton, setDisableValidateButton] = useState<boolean>(true);
  const [generatedMedicalOrderId, setGeneratedMedicalOrderId] = useState<string | null>(null);
  const [patient, setPatient] = useState<Omit<PrescriptionPatient, "age"> & { age: string }>({
    name: "",
    last_name: "",
    document_number: "",
    age: "",
    phone: "+569",
    address: "",
    date_of_birth: "",
    email: "",
  });
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  const [clipboardState, copyToClipboard] = useCopyToClipboard();

  const validateForm = () => {
    if (patient.address === "") {
      setFormErrors({ ...formErrors, address: "Debes ingresar una dirección" });
    }
  };

  const preparedCartLink = useMemo(() => {
    return `https://${domain}/cl/checkout/carrito-de-compras/?services=${selectedServices
      .map((item: Service) => {
        return item.id;
      })
      .join(",")}`;
  }, [selectedServices]);

  const [extraPrescriptionData, setExtraPrescriptionData] = useState<ExtraPrescriptionData>({
    folio_number: 1000,
    date: "29 de enero 2022",
    comments: "",
  });

  const handlePatientChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "document_number" && chileanPatient === true) {
      e.target.value = rutParser(e.target.value);
    }
    setPatient({
      ...patient,
      [e.target.name]: e.target.value,
    });
    if (generatedMedicalOrderId) {
      setGeneratedMedicalOrderId(null);
    }
  };

  const handleExtraDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExtraPrescriptionData({
      ...extraPrescriptionData,
      [e.target.name]: e.target.value,
    });
    if (generatedMedicalOrderId) {
      setGeneratedMedicalOrderId(null);
    }
  };

  const fetchPatient = async (queryPatientId: string, fetchingInfo: "patientId" | "documentNumber") => {
    setPreLoadingStatus(true);
    const queriedPatient =
      fetchingInfo === "patientId"
        ? await getPatient(session?.accessToken, queryPatientId)
        : await getPatientByDocumentNumber(session?.accessToken, queryPatientId);
    if (queriedPatient) {
      const parsedPatient: PrescriptionPatient = {
        ...patient,
        email: queriedPatient.email,
        name: queriedPatient.first_name,
        last_name: queriedPatient.last_name,
        document_number: queriedPatient.document_number,
        age: moment().diff(queriedPatient.date_of_birth, "years", false),
        phone: queriedPatient.phone,
        date_of_birth: moment(queriedPatient.date_of_birth).format("L"),
      };
      setPatient({ ...parsedPatient, age: String(parsedPatient.age) });
    }
    setPreLoadingStatus(false);
  };

  const addManualService = () => {
    setSelectedServices((prevServices) => {
      return [...prevServices, { id: uuidv4(), name: newService, available: false }];
    });
    if (generatedMedicalOrderId) {
      setGeneratedMedicalOrderId(null);
    }
    setNewService("");
  };

  const removeService = (serviceId: string) => {
    const newServiceArray = selectedServices.filter((item: Service) => item.id !== serviceId);
    setSelectedServices(newServiceArray);
    if (generatedMedicalOrderId) {
      setGeneratedMedicalOrderId(null);
    }
  };

  const handleForeignPatient = () => {
    if (chileanPatient) {
      setChileanPatient(false);
    } else {
      setChileanPatient(true);
    }
  };

  useEffect(() => {
    if (rehydrated) {
      if (!session) {
        navigate("/");
      } else if (!medic?.active) {
        navigate("/app");
      }
    }
  }, [rehydrated, session, medic]);

  useEffect(() => {
    if (session) {
      const refreshMedicProfile = async () => {
        const res = await getMedicProfile(session?.accessToken || "");
        if (res) {
          setMedicKeys(res.data);
        }
      };
      refreshMedicProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  useEffect(() => {
    if (patientId && session) {
      fetchPatient(patientId, "patientId");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rehydrated]);

  useEffect(() => {
    if (patient.address !== "") {
      setFormErrors({ ...formErrors, address: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient]);

  // add a useEffect() hook for /app/prescriptions?company_id={} parameter, use to populate initial patient data.

  return (
    <DashboardLayout
      header={
        <div className={clsx("bg-white", "p-5", "w-full", "border-b", "border-gray-200")}>
          <div className="text-lg md:text-3xl mt-[5px] font-medium">Solicita los exámenes de tus pacientes</div>
          <div className="text-xs md:text-md text-examedi-gray-strong mt-[5px]">Nosotros nos encargamos de todo</div>
        </div>
      }
    >
      <div className={clsx("flex flex-col-reverse lg:flex-row", "w-[711.5px] lg:w-[1311.5px]")}>
        <PrescriptionPreview
          hasRut={chileanPatient}
          address={fullAddress}
          extraPrescriptionData={extraPrescriptionData}
          patient={patient}
          patientId={patientId}
          services={selectedServices}
          telehealthId={telehealthId}
          generatedMedicalOrderId={generatedMedicalOrderId}
          setGeneratedMedicalOrderId={setGeneratedMedicalOrderId}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
        />

        <div
          className={clsx(
            "relative",
            "w-full lg:w-[600px]",
            "border-gray-200",
            "drop-shadow-md",
            "ml-3",
            "mb-7 md:mb-0",
          )}
        >
          <div className={clsx("w-full", "rounded-lg", "border-gray-200", "bg-white")}>
            <div className="rounded-t-lg bg-gray-200 py-2.5 px-5 text-sm text-gray-600 font-bold">
              Información del paciente
            </div>
            <div className="p-5">
              <div className="grid grid-cols-12 w-full gap-2.5">
                <div className="mt-2 grid grid-cols-6 col-span-6 text-sm h-[30px] items-center">
                  <Switch className="col-span-2" onChange={() => handleForeignPatient()} checked={chileanPatient} />
                  <div className="col-span-4">{chileanPatient ? "Paciente tiene RUT" : "Solo Pasaporte"}</div>
                </div>
                <div className="col-span-12 text-sm flex items-center">
                  <div>Dirección:</div>
                  <div className="relative">
                    <InputAddress
                      passUpFunction={(address: string) => {
                        setPatient({ ...patient, address });
                      }}
                      addressSetter={(_addressObj: ParsedAddress) => {
                        setFullAddress(_addressObj);
                        if (generatedMedicalOrderId) {
                          setGeneratedMedicalOrderId(null);
                        }
                      }}
                      placeholder="Dirección del paciente"
                      containerClassName={clsx(
                        "ml-[5px] border-zinc-400",
                        formErrors.address && formErrors.address !== "" && "border-red-500",
                      )}
                      onBlur={validateForm}
                      onFocus={() => {
                        setFormErrors({ ...formErrors, address: "" });
                      }}
                    />
                    {formErrors.address && formErrors.address !== "" && (
                      <div className="text-xs text-red-500 ml-2 absolute">{formErrors.address}</div>
                    )}
                  </div>
                </div>
                <div className="mt-2 grid grid-cols-6 col-span-6 text-sm h-[30px] items-center">
                  <div className="col-span-2">Nombre:</div>
                  <input
                    onChange={(e) => {
                      handlePatientChange(e);
                    }}
                    value={patient.name}
                    name="name"
                    className={clsx(
                      "w-full block bg-white col-span-4",
                      "focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 border border-slate-300",
                      "rounded-md shadow-sm",
                      "placeholder:italic placeholder:text-slate-400 sm:text-sm",
                      "py-2 px-3",
                    )}
                  />
                </div>
                <div className="mt-2 grid grid-cols-6 col-span-6 text-sm h-[30px] items-center">
                  <div className="col-span-2">Apellido:</div>
                  <input
                    onChange={(e) => {
                      handlePatientChange(e);
                    }}
                    value={patient.last_name}
                    name="last_name"
                    className={clsx(
                      "w-full block bg-white col-span-4",
                      "focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 border border-slate-300",
                      "rounded-md shadow-sm",
                      "placeholder:italic placeholder:text-slate-400 sm:text-sm",
                      "py-2 px-3",
                    )}
                  />
                </div>
                <div className="mt-2 grid grid-cols-6 col-span-6 text-sm h-[30px] items-center">
                  <div className="col-span-2">{chileanPatient ? "RUT" : "Pasaporte"}</div>
                  <div className="w-full relative grid grid-cols-6 col-span-4 items-center">
                    <input
                      onChange={(e) => {
                        handlePatientChange(e);
                        if (e.target.value.length < 11) {
                          setDisableValidateButton(true);
                        } else {
                          setDisableValidateButton(false);
                        }
                      }}
                      value={patient.document_number}
                      maxLength={12}
                      name="document_number"
                      className={clsx(
                        "w-full block bg-white col-span-6",
                        "focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 border border-slate-300",
                        "rounded-md shadow-sm",
                        "placeholder:italic placeholder:text-slate-400 sm:text-sm",
                        "py-2 px-3",
                      )}
                    />
                    <button
                      className={clsx(
                        "rounded-md bg-examedi-blue-strong hover:bg-examedi-blue-strong-75",
                        "text-white",
                        "absolute top-.5 right-0",
                        "py-2 px-3",
                        "disabled:bg-examedi-gray-4 disabled:hover:opacity-100",
                      )}
                      onClick={async () => {
                        if (chileanPatient) {
                          fetchPatient(patient.document_number, "documentNumber");
                        }
                      }}
                      disabled={disableValidateButton}
                    >
                      Validar
                    </button>
                  </div>
                </div>
                <div className="mt-2 grid grid-cols-6 col-span-6 text-sm h-[30px] items-center">
                  <div className="col-span-2">Edad:</div>
                  <input
                    onChange={(e) => {
                      handlePatientChange(e);
                    }}
                    name="age"
                    value={patient.age}
                    className={clsx(
                      "w-full block bg-white col-span-4",
                      "focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 border border-slate-300",
                      "rounded-md shadow-sm",
                      "placeholder:italic placeholder:text-slate-400 sm:text-sm",
                      "py-2 px-3",
                    )}
                  />
                </div>
                <div className="mt-2 grid grid-cols-6 col-span-6 text-sm h-[30px] items-center">
                  <div className="col-span-2">Celular:</div>
                  <input
                    onChange={(e) => {
                      handlePatientChange(e);
                    }}
                    name="phone"
                    value={patient.phone}
                    className={clsx(
                      "w-full block bg-white col-span-4",
                      "focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 border border-slate-300",
                      "rounded-md shadow-sm",
                      "placeholder:italic placeholder:text-slate-400 sm:text-sm",
                      "py-2 px-3",
                    )}
                  />
                </div>

                <div className="relative mt-2 grid grid-cols-6 col-span-6 text-sm h-[30px] items-center">
                  <div className="col-span-2">Fecha de Nacimiento:</div>
                  <input
                    onChange={(e) => {
                      handlePatientChange(e);
                    }}
                    placeholder="DD/MM/AAAA"
                    name="date_of_birth"
                    value={patient.date_of_birth}
                    className={clsx(
                      "col-span-4",
                      "w-full",
                      "block",
                      "bg-white",
                      "border border-slate-300",
                      patient.date_of_birth &&
                        patient.date_of_birth.length > 6 &&
                        !/\d{2}\/\d{2}\/\d{4}/.test(patient.date_of_birth) &&
                        "!border-red-500 border-[2px]",
                      !(
                        patient.date_of_birth &&
                        patient.date_of_birth.length > 6 &&
                        !/\d{2}\/\d{2}\/\d{4}/.test(patient.date_of_birth)
                      ) && "focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm",
                      "rounded-md",
                      "py-2 px-3",
                      "shadow-sm",
                    )}
                  />
                  {patient.date_of_birth && !/\d{2}\/\d{2}\/\d{4}/.test(patient.date_of_birth) && (
                    <div className={clsx("absolute top-10 text-examedi-gray-strong left-24")}>p. ej. 23/01/1995</div>
                  )}
                </div>
                <div className="mt-4 col-span-12 text-sm">
                  <div className="text-sm text-gray-500 font-bold">Diagnóstico o Comentarios</div>
                  <textarea
                    onChange={(e: any) => {
                      handleExtraDataChange(e);
                    }}
                    name="comments"
                    value={extraPrescriptionData.comments}
                    className="mt-1 w-full placeholder:italic placeholder:text-slate-400 block bg-white  border border-slate-300 rounded-md py-2 px-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={clsx("w-full", "rounded-lg", "border-gray-200", "bg-white", "mt-3")}>
            <div className="rounded-t-lg bg-gray-200 py-2.5 px-5 text-sm text-gray-600 font-bold">
              Seleccionar Exámenes
            </div>
            <div className="p-5">
              <div className="w-full h-[45px] bg-gray-100 rounded-lg">
                <InputSearchServices
                  icon={<MagnifyingGlassGrayIcon className="w-4 h-4" />}
                  rounding="lg"
                  divProps={{ className: clsx("border border-gray-200") }}
                  inputProps={{
                    className: clsx("placeholder-examedi-black-light", "text-sm", "ml-4", "w-[90%]", "py-2"),
                    placeholder: "¿Qué examen buscas?",
                  }}
                  handleServiceSelect={(as) => {
                    setSelectedServices((prevServices) => {
                      return [...prevServices, { id: as.id, name: as.name, available: true }];
                    });
                    if (generatedMedicalOrderId) {
                      setGeneratedMedicalOrderId(null);
                    }
                  }}
                  resultProps={{
                    className: clsx(
                      "max-h-96 xl:max-h-120",
                      "drop-shadow-2xl",
                      "border border-gray-200",
                      "text-sm xl:text-base",
                    ),
                  }}
                />
              </div>
              <div className="grid grid-cols-2 gap-1 mt-2">
                {selectedServices.map((service: Service, i: number) => (
                  <div className="col-span-1 text-xs p-2 bg-gray-100 flex items-center justify-between" key={i}>
                    <div>{service.name}</div>
                    <div
                      onClick={() => {
                        removeService(service.id);
                      }}
                      className="hover:text-red-400 hover:cursor-pointer font-semibold"
                    >
                      Eliminar
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-4 text-sm font-medium">¿No encuentras el examen? Agrégalo por nombre</div>
              <div className="grid grid-cols-7 gap-4 mt-4">
                <input
                  className="col-span-5 placeholder:italic placeholder:text-slate-400 block bg-white  border border-slate-300 rounded-md py-2 px-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                  placeholder="Nombre del Examen"
                  type="text"
                  value={newService}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setNewService(e.target.value);
                  }}
                  name="search"
                />
                <button
                  onClick={() => {
                    addManualService();
                  }}
                  className="col-span-2 rounded-full bg-examedi-blue-strong hover:bg-examedi-blue-strong-75 py-[5px] px-5 text-sm text-white hover:cursor-pointer"
                >
                  Agregar
                </button>
              </div>
              {!!selectedServices?.length && (
                <div>
                  <div className="mt-8 text-sm text-gray-500">
                    <span className="font-bold">Link de agendamiento</span>
                    <span> ({clipboardState.value ? "¡copiado!" : "click para copiar"})</span>
                  </div>
                  <div
                    onClick={() => {
                      copyToClipboard(preparedCartLink);
                    }}
                    className="text-blue-400 text-xs cursor-pointer"
                  >
                    {preparedCartLink}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Prescriptions;
