import qs from "qs";

import client from "~/src/api-client";
import { fonasaForbiddenCommunes } from "~/src/constants/fonasa";
import { SupportedCommune } from "~/src/constants/places";
import { ALLOWED_VIH_SERVICES_CODE_CL } from "~/src/constants/vih";
import { LabServiceSearchItem } from "~/src/types/models";
import { SearchServices, SearchServicesReturn, SearchServicesPayload } from "~/src/types/services";
import { VIHServicesCodeClType } from "~/src/types/vih";
import { sortElasticSearchItemsResponse } from "~/src/utils/services";

export async function searchServices({
  commune,
  countryCode,
  query,
  category,
}: SearchServices): Promise<SearchServicesReturn | undefined> {
  const country = countryCode === "cl" ? "Chile" : "México";
  try {
    const res = await client.get<SearchServicesPayload>(
      `/v2/search/marketplace/?${qs.stringify(
        {
          query: query,
          commune: commune || null,
          country: country,
          category: category !== "all" ? category : null,
        },
        { skipNulls: true, addQueryPrefix: false },
      )}`,
    );
    if (!res.data?.data) return [];
    if (commune && fonasaForbiddenCommunes.includes(commune as SupportedCommune)) {
      const filteredData = res.data!.data!.filter(
        (item) => item.meta.type !== "plan" || !item.name.toLowerCase().includes("fonasa"),
      );

      return sortElasticSearchItemsResponse(filteredData);
    }
    if (res.data.data) {
      const results = sortElasticSearchItemsResponse(res.data.data);

      // TODO: delete when pcr nasal fonasa does not exist anymore ================
      const nasal = results.find(
        (result) => result.name.match(/PCR/) && result.name.match(/Nasal/),
      ) as LabServiceSearchItem;
      const indexOfPCRNasalFonasa = results.findIndex(
        (result) => result.name.match(/PCR/) && result.name.match(/Nasal/) && result.name.match(/Fonasa/),
      );
      if (nasal && !!~indexOfPCRNasalFonasa && results[indexOfPCRNasalFonasa]) {
        const pcrNasal = results[indexOfPCRNasalFonasa];
        if (pcrNasal) {
          results[indexOfPCRNasalFonasa] = {
            ...pcrNasal,
            replaceWhenAddToCart: nasal,
            enableFonasaWhenAddToCart: true,
          };
        }
      }
      // TODO delete when promotion_price is a valid param
      for (const item of results) {
        if (
          item.meta.type === "lab_service" &&
          ALLOWED_VIH_SERVICES_CODE_CL.includes(item.code as VIHServicesCodeClType)
        ) {
          item.promotion_price = item.price * 0.85;
        }
      }
      return results;
      // ===========================================================================
    }
    return [];
  } catch (err) {
    return undefined;
  }
}
