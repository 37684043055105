import React, { Dispatch, SetStateAction, useRef, useState } from "react";

import "moment/locale/es";
import clsx from "clsx";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import moment from "moment";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";

import {
  emailMedicalOrder,
  generateMedicalOrder,
  sendPrescriptionToCrm,
  uploadGeneratedPrescriptionLog,
} from "~/src/api-client/prescription";
import { useContextValue } from "~/src/context";
import ExamediIcon from "~/src/icons/ExamediIcon";
import { ParsedAddress } from "~/src/types/addresses";
import { PrescriptionPatient, Service, ExtraPrescriptionData } from "~/src/types/models";
import { getAddressShort } from "~/src/utils/address";
import namespaced from "~/src/utils/debug";

moment.locale("es");

const debug = namespaced("components/Prescription/PrescriptionPreview");

const generatedId = uuidv4();

const noTitleSpecialties = ["Matrona", "Psicólogo", "Psicóloga"];

const PrescriptionPreview = ({
  hasRut,
  services,
  patient,
  patientId,
  extraPrescriptionData,
  className,
  address,
  telehealthId,
  generatedMedicalOrderId,
  setGeneratedMedicalOrderId,
  formErrors,
  setFormErrors,
}: {
  hasRut: boolean;
  services: Service[];
  patient: Omit<PrescriptionPatient, "age"> & { age: string };
  patientId?: string | null;
  extraPrescriptionData: ExtraPrescriptionData;
  className?: string;
  address: ParsedAddress | undefined;
  telehealthId?: string | null;
  generatedMedicalOrderId: string | null;
  setGeneratedMedicalOrderId: Dispatch<SetStateAction<string | null>>;
  formErrors: Record<string, string>;
  setFormErrors: Dispatch<SetStateAction<Record<string, string>>>;
}) => {
  const { user, medic, session } = useContextValue();
  const [loading, setLoading] = useState<boolean>(false);

  const prescriptionRef = useRef<HTMLDivElement>(null);
  const signatureRef = useRef<HTMLImageElement>(null);

  const foundLabServicesIds = services.filter((service) => service.available).map((item: Service) => item.id);
  const foundServicesNames = services.filter((service) => service.available).map((item) => item.name);
  const nonAvailableServicesNames = services.filter((service) => !service.available).map((service) => service.name);

  const generatePdfFromPrescription = () => {
    if (prescriptionRef?.current) {
      return html2canvas(prescriptionRef.current, { scale: 2.5 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png", 1.0);
        const pdf = new jsPDF("p", "mm", "a4");

        const width = pdf.internal.pageSize.getWidth();
        const height = pdf.internal.pageSize.getHeight();

        debug("width", width);
        debug("height", height);

        pdf.addImage(imgData, "PNG", 0, 0, 210, 297, "", "FAST");
        if (medic?.signature) {
          let imageWidth, imageHeight;
          /**
           * Make sure signature has a proper size and placement
           */
          if (signatureRef?.current?.offsetWidth && signatureRef?.current?.offsetHeight) {
            imageWidth = Math.min(signatureRef.current.offsetWidth, 40);
            const transformationRatio = imageWidth / signatureRef.current.offsetWidth;
            imageHeight = signatureRef.current.offsetHeight * transformationRatio;
          } else {
            imageWidth = 40;
            imageHeight = 30;
          }

          const corsImageModified = new Image();
          corsImageModified.crossOrigin = "Anonymous";
          corsImageModified.src = medic.signature + "?not-from-cache-please";

          pdf.addImage(
            corsImageModified,
            (medic.signature as string).toLowerCase().includes("png") ? "PNG" : "JPEG",
            width - imageWidth - 24,
            height - imageHeight - 38,
            imageWidth,
            imageHeight,
            "",
            "FAST",
          );
        }

        return pdf;
      });
    } else {
      return null;
    }
  };

  const downloadPdfDocument = async () => {
    const pdfPrescription = await generatePdfFromPrescription();
    if (pdfPrescription) {
      pdfPrescription.save(`${patient.name}-${moment().format("L")}.pdf`);
    }
  };

  const handleGenerateMedicalOrder = async (email: string) => {
    if (session) {
      setLoading(true);
      const pdfPrescription = await generatePdfFromPrescription();
      const fileToUpload = pdfPrescription?.output("blob");
      const combinedList = foundServicesNames.concat(nonAvailableServicesNames);
      const services_in_order = combinedList.join(",");
      const logData = {
        patient_phone: patient.phone,
        patient_address: address ? getAddressShort(address) : "None",
        patient_email: email,
        services_selected: services_in_order,
        patient_country: "cl",
        source: "platform",
        medic: medic?.id,
      };
      const data = {
        patient_id: patientId,
        patient_info: {
          first_name: patient.name.split(" ")[0],
          last_name: patient.last_name.split(" ")[0],
          email: email,
          phone: patient.phone,
          document_number: patient.document_number,
          date_of_birth: moment(patient.date_of_birth, "DD/MM/YYYY").format("YYYY-MM-DDT12:00:00"),
          document_type: "ci",
        },
        address_line_1: address ? getAddressShort(address) : null,
        target_lng: address ? address.longitude : 0,
        target_lat: address ? address.latitude : 0,
        target_address: patient.address,
        commune: address?.commune,
        non_available_services: nonAvailableServicesNames,
        lab_service_ids: foundLabServicesIds,
        telehealth_id: telehealthId,
      };

      const res = await generateMedicalOrder({ token: session.accessToken, data, file: fileToUpload });
      uploadGeneratedPrescriptionLog(logData);
      if (res) {
        setGeneratedMedicalOrderId(res.medical_order_id);
        debug("Email sent");
        Swal.fire({
          icon: "success",
          title: `Orden médica enviada con éxito al correo del paciente (${email})`,
        });
      } else {
        debug("Email error");
        Swal.fire({
          icon: "error",
          title: `Hubo un error al enviar la orden médica al correo del paciente (${email})`,
        });
      }
      setLoading(false);
    }
  };

  const confirmEmailAndGenerateMedicalOrder = () => {
    Swal.fire({
      title: "Correo de tu paciente",
      html: `<input type="text" id="patient_email" class="swal2-input" placeholder="Correo">`,
      confirmButtonText: "Enviar orden médica",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      preConfirm: async () => {
        const patient_email = document?.getElementById?.("patient_email").value || "";
        if (session && patient_email) {
          Swal.showLoading();
          handleGenerateMedicalOrder(patient_email);
        }
      },
    });
  };

  const confirmEmailAndSendGeneratedMedicalOrder = () => {
    Swal.fire({
      title: "Datos del destinatario",
      html: `
      <input type="text" id="fullname" class="swal2-input" placeholder="Nombre">
      </br>
      <input type="text" id="email" class="swal2-input" placeholder="Correo">
      `,
      confirmButtonText: "Enviar orden médica",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      preConfirm: async () => {
        const email = document?.getElementById?.("email").value || "";
        const fullname = document?.getElementById?.("fullname").value || "";
        if (session && email && fullname && generatedMedicalOrderId) {
          Swal.showLoading();
          const data = {
            lab_service_ids: foundLabServicesIds,
            email: email,
            fullname: fullname,
          };
          const res = await emailMedicalOrder({
            data: data,
            token: session.accessToken,
            ordenMedicaId: generatedMedicalOrderId,
          });
          if (res) {
            Swal.fire({
              icon: "success",
              title: `Orden médica enviada con éxito al correo: (${email})`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: `Hubo un error al enviar la orden médica al correo: ${email} `,
            });
          }
        }
      },
    });
  };

  return (
    <div
      className={clsx(`w-[711.5px] h-[990px] border-[1px] border-gray-200 rounded-lg shadow-lg bg-white ${className}`)}
    >
      <div className="bg-gray-200 py-2.5 px-6 flex justify-between items-center">
        <div className="text-sm text-gray-600 font-medium">Orden Médica</div>
        {!generatedMedicalOrderId && (
          <button
            onClick={() => {
              if (patient.address !== "") {
                if (patient.email) {
                  handleGenerateMedicalOrder(patient.email);
                } else {
                  confirmEmailAndGenerateMedicalOrder();
                }
              } else {
                //add address key with debes agregar direccion value if it doesnt exist in formerror
                setFormErrors({ ...formErrors, address: "Debes agregar una dirección" });
              }
            }}
            className={clsx(
              "ml-2",
              "px-4",
              "py-2",
              "text-white",
              "rounded-full",
              "bg-examedi-blue-strong hover:bg-examedi-blue-strong-75",
              "text-xs",
            )}
          >
            {loading ? "Generando orden médica..." : "Generar orden médica"}
          </button>
        )}
        {generatedMedicalOrderId && (
          <div className="flex items-center">
            <button
              onClick={() => {
                downloadPdfDocument();
              }}
              className={clsx(
                "px-4",
                "py-2",
                "text-white",
                "rounded-full",
                "bg-examedi-blue-strong hover:bg-examedi-blue-strong-75",
                "text-xs",
              )}
            >
              Descargar
            </button>
            <button
              onClick={() => {
                confirmEmailAndSendGeneratedMedicalOrder();
              }}
              className={clsx(
                "ml-2",
                "px-4",
                "py-2",
                "text-white",
                "rounded-full",
                "bg-examedi-blue-strong hover:bg-examedi-blue-strong-75",
                "text-xs",
              )}
            >
              Enviar vía correo
            </button>
          </div>
        )}
      </div>

      <div className="p-[40px]" id="downloadPrescription" ref={prescriptionRef}>
        <div className="w-full flex justify-between">
          <div>
            <div className="font-medium text-xs">
              {!noTitleSpecialties.includes(medic?.title) && <>{user?.gender === "female" ? "Dra. " : "Dr. "}</>}
              {user?.fullname}
            </div>
            <div className="text-xs">{medic?.title}</div>
            {medic?.subtitle && <div className="text-xs">{medic.subtitle}</div>}
            <div className="text-xs">Rut: {user?.document_number}</div>
            <div className="text-xs">Av. El Bosque Norte 500 (of. 1102), Las Condes</div>
          </div>
          <ExamediIcon />
        </div>

        <div className="font-medium text-md md:text-xl text-uppercase h-[60px] flex justify-center items-center">
          <span>Orden de Exámenes</span>
        </div>

        <div className="text-right h-[40px] text-xs flex justify-end items-center">
          <span>Fecha: {moment().format("L")}</span>
        </div>

        <div className="border-[1px] text-xs text-examedi-black-dark w-full h-[140px] border-examedi-gray-strong rounded-sm px-4 pb-4 grid grid-cols-5 gap-y-2">
          <div className={clsx("col-span-3 grid grid-cols-4")}>
            <div className="col-span-1 self-center">Nombre:</div>
            <div className="col-span-3 self-center h-5 mr-4 font-medium">
              {patient.name} {patient.last_name}
            </div>
          </div>
          <div className={clsx("col-span-2 grid grid-cols-5")}>
            <div className="col-span-2 self-center">{hasRut ? "RUT" : "Pasaporte"}:</div>
            <div className="col-span-3 self-center h-5 mr-4 font-medium">{patient.document_number}</div>
          </div>
          <div className={clsx("col-span-3 grid grid-cols-4")}>
            <div className="col-span-1 self-center">Edad:</div>
            <div className="col-span-3 self-center h-5 mr-4 font-medium">
              {patient.age} {patient.age && "años"}
            </div>
          </div>
          <div className={clsx("col-span-2 grid grid-cols-5")}>
            <div className="col-span-2 self-center">Celular:</div>
            <div className="col-span-3 self-center h-5 mr-4 font-medium">{patient.phone}</div>
          </div>
          <div className={clsx("col-span-3 grid grid-cols-4")}>
            <div className="col-span-1 self-center">Dirección:</div>
            <div className="col-span-3 self-center h-5 mr-4 font-medium">{patient.address}</div>
          </div>
          <div className={clsx("col-span-2 grid grid-cols-5")}>
            <div className="col-span-2 self-center">Fecha de Nacimiento:</div>
            <div className="col-span-3 self-center h-5 mr-4 font-medium">{patient.date_of_birth}</div>
          </div>
        </div>

        <div className="h-[80px]">
          <div className="font-medium text-sm mt-6">Diagnóstico: </div>
          <div className="mt-[4px] text-xs">{extraPrescriptionData.comments}</div>
        </div>
        <div className="h-[330px]">
          <div className="font-medium text-sm">Exámenes: </div>
          <div
            className={clsx(
              "border-blue-200",
              "text-xs",
              "mt-[4px]",
              "w-full",
              "grid",
              !services || (services.length <= 8 && "grid-cols-1"),
              services && services.length > 8 && services.length <= 16 && "grid-cols-2",
              services && services.length > 16 && "grid-cols-3",
            )}
          >
            {services.map((service: Service, i: number) => (
              <div key={i} className="h-[25px] w-full bg-red">
                {service.name}
              </div>
            ))}
          </div>
        </div>

        <div className="flex items-end justify-between h-[120px]">
          <div className="w-full font-medium text-xs">
            Folio: <u>{generatedId}</u>
          </div>
          <div className="w-1/2 text-center text-xs">
            {medic?.signature && (
              <div className={clsx("mb-2 flex justify-center items-center")}>
                <img
                  ref={signatureRef}
                  className="max-w-[130px] max-h-[130px]"
                  alt=""
                  src={medic.signature as string}
                />
              </div>
            )}
            <div className="font-semibold">
              {!noTitleSpecialties.includes(medic?.title) && <>{user?.gender === "female" ? "Dra. " : "Dr. "}</>}
              {user?.fullname}
            </div>
            {medic?.title && <div>{medic.title}</div>}
            {medic?.subtitle && <div>{medic.subtitle}</div>}
            {user?.document_number && <div>{user.document_number}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrescriptionPreview;
