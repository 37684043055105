import React from "react";

function MagnifyingGlassGrayIcon(props: React.ComponentPropsWithoutRef<"svg">) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="10" cy="10" r="5.99165" transform="rotate(45 10 10)" stroke="#B1B1B1" strokeWidth="2.15884" />
      <path d="M14.4998 14.335L18.2909 18.1261" stroke="#B1B1B1" strokeWidth="2.15884" strokeLinecap="round" />
    </svg>
  );
}

export default MagnifyingGlassGrayIcon;
