import React, { useEffect, useRef, useState } from "react";

import clsx from "clsx";

import { forwardGeocoding } from "~/src/api-client/google-maps";
import { useContextUpdate, useContextValue } from "~/src/context";
import useDebounce from "~/src/hooks/useDebounce";
import useOnClickOutside from "~/src/hooks/useOnClickOutside";
import LocationIcon from "~/src/icons/LocationIcon";
import LocationRedIcon from "~/src/icons/LocationRedIcon";
import { ParsedAddress } from "~/src/types/addresses";
import { getAddressFull, getAddressNormal } from "~/src/utils/address";
import namespaced from "~/src/utils/debug";

const debug = namespaced("components/inputs/InputAddress", { includeStaging: true });

type InputAddressProps = {
  placeholder: string;
  containerClassName?: string;
  resultsClassName?: string;
  inputClassName?: string;
  cb?: (arg0: ParsedAddress) => void; // to handle by external controller
  allowAutofocus?: boolean;
  passUpFunction?: (_address: string) => void;
  addressSetter?: (_addressObj: ParsedAddress) => void;
  onBlur?: () => void;
  onFocus?: () => void;
};

function InputAddress({
  placeholder,
  containerClassName,
  resultsClassName,
  inputClassName,
  passUpFunction,
  addressSetter,
  cb,
  allowAutofocus,
  onBlur,
  onFocus,
}: InputAddressProps) {
  const [addressSearch, setAddressSearch] = useState<string>("");
  const [showTipsAndResultsBox, setShowTipsAndResultsBox] = useState<boolean>(false);
  const [mapboxResults, setMapboxResults] = useState<ParsedAddress[]>([]);

  const { setCurrentAddress } = useContextUpdate();
  const { isForcingAddress } = useContextValue();

  const containerRef = useRef<HTMLDivElement>(null);

  const getAddressInfo = async () => {
    const res = await forwardGeocoding(addressSearch);
    if (res) {
      debug("received parsed address:\n", res);
      setMapboxResults(res);
    } else {
      debug("failed receiving parsed address");
    }
  };

  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    }
    setShowTipsAndResultsBox(true);
  };

  const hideTipsAndResultsBox = () => setShowTipsAndResultsBox(false);

  const handleChange = (ev: React.FormEvent<HTMLInputElement>) => {
    setAddressSearch(ev.currentTarget.value);
    setMapboxResults((prevState) => (prevState?.length ? [] : prevState));
  };

  const handleKeyPress = (ev: React.KeyboardEvent<HTMLInputElement>) => (ev.key === "Enter" ? getAddressInfo() : {});

  const invokeDebounced = useDebounce(async () => getAddressInfo(), 400, { leading: false, trailing: true });

  const handleAddressClick = (addressClicked: ParsedAddress) => {
    setAddressSearch(getAddressNormal(addressClicked));
    if (addressSetter) {
      addressSetter(addressClicked);
    }
    if (passUpFunction) {
      passUpFunction(getAddressNormal(addressClicked));
    }
    if (cb) {
      cb(addressClicked);
      setShowTipsAndResultsBox(false);
      return;
    }
    /**
     * This method also adds the address to the "allAddresses" context list
     */
    setCurrentAddress(addressClicked);
    setShowTipsAndResultsBox(false);
  };

  useOnClickOutside(containerRef, () => {
    debug("called from InputAddress");
    hideTipsAndResultsBox();
  });

  useEffect(() => {
    invokeDebounced();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressSearch]);

  return (
    <div className={clsx("w-full relative")}>
      {isForcingAddress && (
        <span
          className={clsx(
            "absolute",
            "hidden 2xl:block",
            "top-2 3xl:top-0",
            "2xl:-left-24 3xl:-left-32",
            "text-6xl 3xl:text-7xl",
            "bounce-right",
          )}
        >
          👉🏻
        </span>
      )}
      <div
        ref={containerRef}
        className={clsx(
          "w-full",
          "lg:max-w-[37rem] 2lg:max-w-[40rem] xl:max-w-[45rem]",
          "rounded-t-xl",
          !showTipsAndResultsBox && "rounded-xl",
          !isForcingAddress && "border border-gray-200",
          isForcingAddress && "border-2 border-examedi-red-strong",
          "grid grid-cols-12",
          "focus:border-gray-700",
          containerClassName,
        )}
      >
        {!isForcingAddress && <LocationIcon className={clsx("col-span-1", "my-auto", "ml-2")} />}
        {isForcingAddress && <LocationRedIcon className={clsx("col-span-1", "my-auto", "ml-2")} />}
        <input
          className={clsx(
            "w-full",
            "ml-[10px]",
            "py-3 lg:py-2",
            "col-span-10",
            "border-0 focus:ring-0",
            "placeholder:text-xs",
            !isForcingAddress && "placeholder-examedi-black-light",
            isForcingAddress && "placeholder-examedi-red-strong",
            isForcingAddress && "placeholder-bold",
            "text-xs xs:text-base",
            "focus:outline-none",
            inputClassName,
          )}
          type="text"
          value={addressSearch}
          placeholder={placeholder}
          onFocus={handleFocus}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          autoFocus={allowAutofocus}
          onBlur={() => {
            if (onBlur) {
              onBlur();
            }
          }}
        />
        <div className={clsx("col-span-12 w-full relative")}>
          {/*
           * No results
           */}
          {showTipsAndResultsBox && !mapboxResults?.length && (
            <div
              className={clsx(
                !isForcingAddress && [
                  "w-[calc(100%_+_2px)] 1xl:w-[calc(100%_+_3px)]",
                  "-ml-[1px]",
                  "border border-zinc-400",
                ],
                isForcingAddress && [
                  "w-[calc(100%_+_4px)] 1xl:w-[calc(100%_+_5px)]",
                  "-ml-[2px]",
                  "border-2 border-examedi-red-strong",
                ],
                "absolute top-0 left-0",
                "bg-white",
                "p-2",
                "rounded-b-2xl",
                "z-10",
                resultsClassName,
              )}
            >
              <div
                className={clsx(
                  "w-full",
                  "h-full",
                  "flex justify-start items-center",
                  "px-3 py-2",
                  "text-sm sm:text-base",
                  "text-gray-500",
                )}
              >
                <span>
                  Escribe &nbsp;<strong>calle, número y comuna</strong>&nbsp;para escoger la dirección.
                </span>
              </div>
            </div>
          )}
          {/*
           * Got results
           */}
          {showTipsAndResultsBox && !!mapboxResults?.length && (
            <div
              className={clsx(
                !isForcingAddress && [
                  "w-[calc(100%_+_2px)] 1xl:w-[calc(100%_+_3px)]",
                  "-ml-[1px]",
                  "border border-zinc-400",
                ],
                isForcingAddress && [
                  "w-[calc(100%_+_4px)] 1xl:w-[calc(100%_+_5px)]",
                  "-ml-[2px]",
                  "border-2 border-examedi-red-strong",
                ],
                "absolute",
                "top-1 left-0",
                "bg-white",
                "p-2",
                "rounded-b-2xl",
                "z-10", // When there are many results, the results box has to be on top of waving hand
              )}
            >
              {mapboxResults.map((mr, index) => (
                <div key={`mr-${index}`} className={clsx("w-full")}>
                  <div
                    className={clsx(
                      "w-full",
                      "flex justify-start items-center",
                      "px-3",
                      "text-sm sm:text-base",
                      "py-2",
                      "cursor-pointer",
                      "hover:bg-gray-200",
                      "rounded-xl",
                    )}
                    onClick={() => handleAddressClick(mr)}
                  >
                    {getAddressFull(mr)}
                  </div>
                  {index + 1 < mapboxResults.length && (
                    <div className={clsx("w-full", "px-3", "flex justify-center")}>
                      <div className={clsx("w-full", "h-[1px]", "bg-gray-200")} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default InputAddress;
