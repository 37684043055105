import { useCallback, useEffect, useRef } from "react";

import debounce from "lodash/debounce";

import useIsMounted from "~/src/hooks/useIsMounted";

/**
 * @param cb - function for debounced execution
 * @param delay - miliseconds to consider calling the callback again
 * @param options.leading - should callback be executed at first function call
 * @param options.trailing - should callback be executed after delay of last function call
 */
function useDebounce(
  cb: (...args: any) => any,
  delay: number,
  options?: { leading: boolean; trailing: boolean },
): (...args: any) => any {
  const opts = {
    leading: options ? options.leading : undefined,
    trailing: options ? options.trailing : undefined,
  };
  const inputsRef = useRef({ cb, delay });
  const isMounted = useIsMounted();
  useEffect(() => {
    inputsRef.current = { cb, delay };
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    debounce(
      (...args) => {
        /**
         * Don't execute callback, if
         * (1) component in the meanwhile has been unmounted or
         * (2) delay has changed
         */
        if (inputsRef.current.delay === delay && isMounted()) {
          inputsRef.current.cb(...args);
        }
      },
      delay,
      opts,
    ),
    [delay, debounce],
  );
}

export default useDebounce;
