import { AxiosError } from "axios";

import {
  EmailMedicalOrderPayload,
  GeneratedMedicalOrder,
  GeneratedMedicalOrderLogData,
  GenerateMedicalOrderPayload,
  LeadData,
} from "../types/prescription";

import client from "~/src/api-client";
import namespaced from "~/src/utils/debug";

const debug = namespaced("api-client/prescription");

export async function generateMedicalOrder({
  token,
  data,
  file,
}: GenerateMedicalOrderPayload): Promise<GeneratedMedicalOrder | undefined> {
  try {
    const formData = new FormData();
    formData.append("files", file);
    if (data.patient_id) {
      formData.append("patient_id", data.patient_id);
    }
    if (data.patient_info) {
      formData.append("patient_info", JSON.stringify(data.patient_info));
    }
    if (data.target_address) {
      formData.append("target_address", data.target_address);
    }
    if (data.address_line_1) {
      formData.append("address_line_1", data.address_line_1);
    }
    if (data.target_lng && data.target_lat) {
      formData.append("target_lng", String(data.target_lng));
      formData.append("target_lat", String(data.target_lat));
    }
    if (data.commune) {
      formData.append("commune", data.commune);
    }
    if (data.telehealth_id) {
      formData.append("telehealth_id", data.telehealth_id);
    }
    data.lab_service_ids.forEach((lab_service_id) => {
      formData.append("lab_service_ids", lab_service_id);
    });

    data.non_available_services.forEach((non_available) => {
      formData.append("non_available_services", non_available);
    });
    const res: any = await client.post("/v2/grouped-medical-orders/generate-doctor-prescription/", formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    debug(`[generateMedicalOrder] response was ${res.status}`, res);
    return res.data;
  } catch (err) {
    debug("[generateMedicalOrder]", err);
    return undefined;
  }
}

export async function emailMedicalOrder({
  data,
  token,
  ordenMedicaId,
}: EmailMedicalOrderPayload): Promise<string | undefined> {
  try {
    const res = await client.post(`/v2/medical-orders/${ordenMedicaId}/email-doctor-prescription/`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    debug(`[emailMedicalOrder] response was ${res.status}`, res);
    return res.data;
  } catch (err) {
    debug("[emailMedicalOrder]", err);
    return undefined;
  }
}

export async function sendPrescriptionToCrm(
  data: LeadData & { sales_source: "Telemedicina" },
  files: File,
  localePath: string,
): Promise<string | undefined> {
  try {
    const formData = new FormData();
    formData.append("orden", files);
    formData.append("lead", JSON.stringify(data));
    formData.append("path", localePath);
    const res: string = await client.post("/orden-medica/create-ticket/", formData);
    debug(`[uploadMedicalOrder] response was ${res}`, res);
    return res;
    // return axios.post(`http://localhost:8000/orden-medica/create-ticket/`, formData);
  } catch (err) {
    debug("[uploadMedicalOrder]", err);
    const error = err as AxiosError;
    if (error.isAxiosError) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const errorMessage = error?.response?.data?.err as string;
      return errorMessage;
    }
    return undefined;
  }
}

export async function uploadGeneratedPrescriptionLog(data: GeneratedMedicalOrderLogData): Promise<string | undefined> {
  try {
    const res = await client.post<GeneratedMedicalOrderLogData>("/generated-prescriptions/", data);
    debug(`[uploadGeneratedPrescriptions] response was ${res.status}`, res);
    return res.data.services_selected;
  } catch (err) {
    debug("[uploadGeneratedPrescriptions]", err);
    const error = err as AxiosError;
    if (error.isAxiosError) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const errorMessage = error?.response?.data?.err as string;
      return errorMessage;
    }
    return undefined;
  }
}
