import { SupportedCommune } from "~/src/constants/places";

/**
 * Fonasa-forbidden communes in Chile
 */
export const fonasaForbiddenCommunes: Partial<SupportedCommune>[] = [
  "El Monte",
  "Isla de Maipo",
  "Melipilla",
  "Peñaflor",
  "Talagante",
];
