import { SupportedCountry, SupportedRegion, UnsupportedRegion } from "~/src/constants/places";
import { ParsedAddress } from "~/src/types/addresses";
import { GoogleMapsGeocodingResult } from "~/src/types/google-maps";
import { ResidenceType } from "~/src/types/models";
import namespaced from "~/src/utils/debug";

const debug = namespaced("utils/google-maps", { includeStaging: true });

function findStreetNumber(result: GoogleMapsGeocodingResult, query: string): string[] | undefined {
  const found = result.address_components.find((cmp) => cmp.types.includes("street_number"));
  if (found?.long_name) {
    return [found.long_name];
  } else {
    return query.match(/\d+/g) || undefined;
  }
}

function findStreet(result: GoogleMapsGeocodingResult): string | undefined {
  const found = result.address_components.find((cmp) => cmp.types.includes("route"));
  return found ? found.long_name : undefined;
}

function findCommune(result: GoogleMapsGeocodingResult): string[] | undefined {
  const fromLocality = result.address_components.find((cmp) => cmp.types.includes("locality"));
  const fromArea3 = result.address_components.find((cmp) => cmp.types.includes("administrative_area_level_3"));
  if (fromLocality?.long_name && fromArea3?.long_name && fromLocality.long_name !== fromArea3.long_name) {
    return [fromArea3.long_name, fromLocality.long_name];
  } else if (fromLocality?.long_name) {
    return [fromLocality.long_name];
  } else if (fromArea3?.long_name) {
    return [fromArea3.long_name];
  } else {
    return undefined;
  }
}

function findRegion(result: GoogleMapsGeocodingResult): string[] | undefined {
  const fromArea1 = result.address_components.find((cmp) => cmp.types.includes("administrative_area_level_1"));
  const fromArea2 = result.address_components.find((cmp) => cmp.types.includes("administrative_area_level_2"));
  if (fromArea1?.long_name && fromArea2?.long_name && fromArea1.long_name !== fromArea2.long_name) {
    return [fromArea2.long_name, fromArea1.long_name];
  } else if (fromArea1?.long_name) {
    return [fromArea1.long_name];
  } else if (fromArea2?.long_name) {
    return [fromArea2.long_name];
  } else {
    return undefined;
  }
}

/**
 * Note:
 *
 * Seems impossible no to find a country because we use country filtering on google map request
 */
function findCountry(result: GoogleMapsGeocodingResult): SupportedCountry | undefined {
  const found = result.address_components.find((cmp) => cmp.types.includes("country"));
  return found ? (found.long_name as SupportedCountry) : undefined;
}

function findZipCode(result: GoogleMapsGeocodingResult): string | undefined {
  const found = result.address_components.find((cmp) => cmp.types.includes("postal_code"));
  return found ? found.long_name : undefined;
}

function translateRegion(raw: string): SupportedRegion | string {
  const mapper: Record<string, SupportedRegion | UnsupportedRegion> = {
    ["Coquimbo"]: "Región de Coquimbo",
    ["Región Metropolitana"]: "Región Metropolitana de Santiago",
    ["Valparaíso"]: "Región de Valparaíso",
    ["Bío Bío"]: "Región del Bío Bío",
    ["Arica y Parinacota"]: "Región de Arica y Parinacota",
    ["Tarapacá"]: "Región de Tarapacá",
    ["Antofagasta"]: "Región de Antofagasta",
    ["Atacama"]: "Región de Atacama",
    ["Libertador General Bernardo O'Higgins"]: "Región del Libertador General Bernardo O'Higgins",
    ["Maule"]: "Región del Maule",
    ["La Araucanía"]: "Región de la Araucanía",
    ["Los Ríos"]: "Región de los Ríos",
    ["Los Lagos"]: "Región de los Lagos",
    ["Aysén del General Carlos Ibáñez del Campo"]: "Región de Aysén del General Carlos Ibáñez del Campo",
    ["Magallanes y Antártica Chilena"]: "Región de Magallanes y de la Antártica Chilena",
  };
  return mapper[raw] || raw;
}

export function parseAddress(result: GoogleMapsGeocodingResult, query: string): ParsedAddress[] | undefined {
  debug("will parse from query", query);
  const country = findCountry(result);
  const numbers = findStreetNumber(result, query);
  const street = findStreet(result);
  const communes = findCommune(result);
  const regions = findRegion(result)?.map(translateRegion);
  const zip_code = findZipCode(result);
  const longitude = result.geometry.location.lng;
  const latitude = result.geometry.location.lat;

  debug("just parsed following address component\n", {
    numbers,
    street,
    communes,
    regions,
    country,
    zip_code,
    longitude,
    latitude,
  });

  if (!numbers || !street || !communes || !regions || !country || !longitude || !latitude) {
    debug("will ignore address because it is missing information", result);
    const missing: string[] = [];
    if (!numbers) missing.push("number");
    if (!street) missing.push("street");
    if (!communes) missing.push("commune");
    if (!regions) missing.push("region");
    if (!country) missing.push("country");
    if (!longitude) missing.push("longitude");
    if (!latitude) missing.push("latitude");
    /**
     * If we found the number, LogRocket this
     */
    return undefined;
  }

  const parsedAddresses: ParsedAddress[] = [];
  for (const number of numbers) {
    for (const commune of communes) {
      for (const region of regions) {
        parsedAddresses.push({
          type: "unknown" as ResidenceType,
          number,
          street,
          commune,
          region,
          country,
          zip_code,
          longitude,
          latitude,
        });
      }
    }
  }
  return parsedAddresses;
}
