import React from "react";

function ExamediIcon(props: React.ComponentPropsWithoutRef<"svg">) {
  return (
    <svg width="93" height="15" viewBox="0 0 93 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_353_2696)">
        <path
          d="M24.3387 15.0012C23.2934 15.0012 22.3774 14.7677 21.5906 14.3008C20.825 13.857 20.1871 13.1934 19.7467 12.3828C19.3067 11.5292 19.0839 10.5642 19.1012 9.5866C19.1012 8.49561 19.3103 7.537 19.7286 6.71076C20.138 5.8943 20.7416 5.21137 21.4768 4.73279C22.2614 4.24416 23.1539 3.996 24.0588 4.01492C24.8852 4.00041 25.6982 4.24312 26.4011 4.71425C27.0919 5.15519 27.6541 5.79794 28.0234 6.56893C28.4175 7.35444 28.6021 8.25305 28.5773 9.26476L28.5582 10.1245H21.5533C21.663 10.8389 22.019 11.4815 22.5493 11.9224C23.0897 12.3588 23.7718 12.577 24.5955 12.577C25.0042 12.5837 25.4106 12.5097 25.7949 12.3588C26.2258 12.1726 26.6247 11.9093 26.9751 11.5798L28.1916 13.4171C27.655 13.9283 27.0361 14.3281 26.3659 14.5964C25.7172 14.8586 25.0311 14.9956 24.3387 15.0012ZM24.0799 6.37255C22.6909 6.37255 21.8548 7.05151 21.5714 8.40942H26.1816V8.32978C26.1693 8.05879 26.1036 7.79379 25.9889 7.55256C25.8743 7.31133 25.7134 7.09945 25.517 6.93114C25.1196 6.55618 24.6067 6.35679 24.0799 6.37255Z"
          fill="#133963"
        />
        <path
          d="M28.9287 14.8015L32.5802 9.4469L28.9841 4.25488H32.0505L34.1904 7.49076L35.9416 4.25488H38.8922L35.5177 9.44908L39.151 14.8026H36.141L33.9085 11.4281L31.8944 14.8037H28.9287V14.8015Z"
          fill="#133963"
        />
        <path
          d="M43.3291 15.001C42.547 15.0105 41.781 14.7595 41.1348 14.2821C40.4685 13.7828 39.9295 13.1092 39.5679 12.3237C39.176 11.4468 38.9804 10.4821 38.9969 9.5079C38.9969 8.42782 39.1872 7.4823 39.5679 6.67133C39.9292 5.88492 40.4765 5.21634 41.154 4.73374C41.8373 4.26565 42.6315 4.0227 43.4399 4.03441C44.0704 4.01764 44.6943 4.17575 45.2525 4.49372C45.7455 4.7649 46.1697 5.16268 46.4882 5.65235V4.25479H48.9956V14.8014H46.46V13.3831C46.0927 13.869 45.6336 14.2641 45.1136 14.5417C44.5624 14.8506 43.9497 15.0083 43.3291 15.001ZM44.0089 12.6281C44.7581 12.6281 45.3667 12.3416 45.8346 11.7684C46.3019 11.1967 46.5395 10.4429 46.5395 9.51226C46.5395 8.58165 46.3059 7.82669 45.8346 7.25501C45.3633 6.68333 44.7581 6.39531 44.0089 6.39531C43.6667 6.38328 43.3264 6.45423 43.0128 6.60297C42.6992 6.75171 42.4202 6.97448 42.1962 7.25501C41.7431 7.82378 41.5165 8.57474 41.5165 9.5079C41.5165 10.4411 41.7437 11.1931 42.1982 11.7641C42.4215 12.0451 42.7 12.2686 43.0133 12.4181C43.3266 12.5676 43.6667 12.6394 44.0089 12.6281Z"
          fill="#133963"
        />
        <path
          d="M51.0419 14.8014V4.25483H53.4769L53.5141 5.75275C53.8574 5.24786 54.2997 4.83166 54.8092 4.53412C55.3013 4.22174 55.8611 4.05647 56.4315 4.05518C57.1324 4.05518 57.7164 4.22173 58.1837 4.55485C58.6496 4.87414 59.0306 5.38691 59.3267 6.09314C59.6841 5.48114 60.176 4.97472 60.7585 4.61925C61.3409 4.26378 61.9956 4.07034 62.6639 4.05626C64.8881 4.05626 66.0005 5.50801 66.0012 8.41149V14.8036H63.4696V8.60896C63.4696 7.1434 62.9164 6.41098 61.81 6.41171C61.2645 6.39335 60.7343 6.60899 60.3347 7.01175C60.148 7.20423 60.0008 7.43702 59.9024 7.69505C59.8041 7.95308 59.7568 8.23061 59.7637 8.50968V14.8025H57.2361V8.56859C57.2361 7.87691 57.0951 7.34414 56.8131 6.9703C56.5312 6.59645 56.126 6.40953 55.5977 6.40953C55.0582 6.39335 54.5347 6.60906 54.1415 7.00957C53.9574 7.19565 53.8115 7.42156 53.7131 7.67264C53.6147 7.92373 53.5662 8.19441 53.5705 8.46713V14.7949L51.0419 14.8014Z"
          fill="#133963"
        />
        <path
          d="M72.5509 15.0012C71.5056 15.0012 70.5896 14.7677 69.8027 14.3007C69.0372 13.857 68.3993 13.1934 67.9589 12.3828C67.5181 11.5294 67.295 10.5643 67.3124 9.58659C67.3124 8.4956 67.5215 7.53698 67.9398 6.71074C68.3503 5.89409 68.9549 5.21119 69.691 4.73278C70.4753 4.24428 71.3675 3.99613 72.272 4.01491C73.0984 4.00039 73.9114 4.24311 74.6143 4.71423C75.3053 5.1549 75.8676 5.79772 76.2366 6.56891C76.6307 7.35442 76.8153 8.25304 76.7905 9.26475L76.7713 10.1244H69.7635C69.8731 10.8388 70.2291 11.4815 70.7594 11.9224C71.2998 12.3588 71.9819 12.577 72.8057 12.577C73.2144 12.5836 73.6208 12.5097 74.005 12.3588C74.4359 12.1726 74.8349 11.9092 75.1853 11.5798L76.4018 13.417C75.8652 13.9283 75.2463 14.3281 74.576 14.5964C73.9282 14.8591 73.2426 14.9961 72.5509 15.0012ZM72.2931 6.37254C70.9048 6.37254 70.0689 7.05149 69.7856 8.40941H74.3958V8.32977C74.383 8.059 74.3172 7.79426 74.2028 7.55313C74.0884 7.31199 73.9279 7.09997 73.7321 6.93112C73.3335 6.55705 72.8203 6.35783 72.2931 6.37254Z"
          fill="#133963"
        />
        <path
          d="M82.0471 15.001C81.2323 15.0151 80.4313 14.7717 79.742 14.3006C79.0577 13.8124 78.5044 13.1373 78.1379 12.3433C77.746 11.466 77.5503 10.5009 77.5669 9.5264C77.5669 8.4616 77.7572 7.51608 78.1379 6.68983C78.502 5.89937 79.0561 5.22998 79.742 4.75224C80.3302 4.34857 81.0005 4.10666 81.6946 4.04752C82.3887 3.98839 83.0856 4.11383 83.7248 4.41294C84.2217 4.63361 84.6684 4.96864 85.0339 5.39483V0.0195312H87.5606V14.8013H85.1639L85.1276 13.5423C84.7673 13.989 84.3202 14.3436 83.8185 14.581C83.264 14.8612 82.659 15.0047 82.0471 15.001ZM82.601 12.723C82.9466 12.7346 83.2903 12.6616 83.6064 12.5096C83.9224 12.3576 84.2028 12.1303 84.4267 11.8448C84.8933 11.2447 85.1283 10.4723 85.1316 9.52749C85.1316 8.56888 84.8966 7.79682 84.4267 7.21133C84.1991 6.92905 83.9184 6.70281 83.6034 6.54779C83.2884 6.39277 82.9464 6.31257 82.6005 6.31257C82.2545 6.31257 81.9125 6.39277 81.5976 6.54779C81.2826 6.70281 81.0018 6.92905 80.7742 7.21133C80.3083 7.79755 80.0734 8.5696 80.0693 9.52749C80.0693 10.473 80.3043 11.2454 80.7742 11.8448C81.2415 12.4317 81.8511 12.7245 82.603 12.723H82.601Z"
          fill="#133963"
        />
        <path
          d="M89.8384 14.8014V4.25485H92.365V14.8014H89.8384ZM92.499 1.50884C92.4988 1.80699 92.417 2.09839 92.2639 2.3462C92.1109 2.594 91.8935 2.78708 91.6391 2.90103C91.3848 3.01498 91.105 3.04469 90.8351 2.98639C90.5652 2.92809 90.3174 2.7844 90.1228 2.5735C89.9283 2.36259 89.7959 2.09394 89.7422 1.80149C89.6886 1.50905 89.7163 1.20595 89.8216 0.930506C89.927 0.655065 90.1054 0.419651 90.3342 0.254026C90.563 0.0884006 90.8321 7.80549e-08 91.1073 0C91.2901 -5.18585e-08 91.4712 0.0390359 91.6401 0.114875C91.809 0.190715 91.9624 0.301873 92.0917 0.441995C92.2209 0.582118 92.3234 0.748458 92.3933 0.931512C92.4632 1.11457 92.4991 1.31074 92.499 1.50884Z"
          fill="#133963"
        />
        <path
          d="M13.1358 8.37324C12.8394 8.3742 12.5529 8.25781 12.3302 8.04594L7.21353 3.17468L2.0183 8.04594C1.89736 8.16143 1.75649 8.24981 1.60384 8.30598C1.45118 8.36216 1.28977 8.38501 1.12891 8.37322C0.968064 8.36143 0.810965 8.31524 0.666686 8.2373C0.522407 8.15936 0.393809 8.05123 0.288321 7.91915C0.182833 7.78707 0.102545 7.63365 0.0520915 7.46777C0.00163838 7.30188 -0.0179807 7.1268 -0.00562915 6.95266C0.00672239 6.77851 0.0507991 6.60874 0.124053 6.45315C0.197307 6.29756 0.29829 6.15923 0.421166 6.04616L6.42201 0.41775C6.6448 0.208331 6.93009 0.093486 7.22505 0.0944889C7.52 0.0954919 7.80463 0.212277 8.0262 0.423207L13.9424 6.05598C14.1287 6.23344 14.2605 6.46814 14.3205 6.72883C14.3806 6.98952 14.3658 7.26383 14.2783 7.51523C14.1909 7.76663 14.0348 7.9832 13.8308 8.13609C13.6269 8.28898 13.3848 8.37094 13.1368 8.37106L13.1358 8.37324Z"
          fill="#133963"
        />
        <path
          d="M9.41566 10.2837H8.28477V9.05521C8.27631 8.75851 8.16157 8.47704 7.96486 8.27042C7.76815 8.06379 7.50491 7.94824 7.23092 7.94824C6.95693 7.94824 6.69371 8.06379 6.497 8.27042C6.30029 8.47704 6.18554 8.75851 6.17708 9.05521V10.2837H5.04115C4.90002 10.2789 4.75945 10.305 4.62778 10.3602C4.49611 10.4155 4.37602 10.4988 4.27465 10.6053C4.17327 10.7118 4.09267 10.8392 4.03763 10.9801C3.98259 11.121 3.95422 11.2724 3.95422 11.4254C3.95422 11.5784 3.98259 11.7298 4.03763 11.8706C4.09267 12.0115 4.17327 12.139 4.27465 12.2455C4.37602 12.352 4.49611 12.4353 4.62778 12.4905C4.75945 12.5458 4.90002 12.5718 5.04115 12.5671H6.17003V13.7956C6.1785 14.0922 6.29324 14.3737 6.48995 14.5803C6.68666 14.787 6.94989 14.9025 7.22388 14.9025C7.49787 14.9025 7.7611 14.787 7.95781 14.5803C8.15452 14.3737 8.26926 14.0922 8.27772 13.7956V12.5671H9.41163C9.68549 12.5579 9.9453 12.4336 10.136 12.2205C10.3267 12.0074 10.4334 11.7222 10.4334 11.4254C10.4334 11.1285 10.3267 10.8434 10.136 10.6303C9.9453 10.4171 9.68549 10.2928 9.41163 10.2837H9.41566Z"
          fill="#008CFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_353_2696">
          <rect width="92.5" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ExamediIcon;
